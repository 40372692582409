import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import Hero from './sections/header/_hero'
import CheckingAccount from './sections/checking-account/_checking-account '

import pageContext from './pageContext.json'

import { Wrapper } from './style'

const DigitalAccount = () => {
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero />
        <CheckingAccount />
      </Layout>
    </Wrapper>
  )
}

export default DigitalAccount
