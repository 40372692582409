import React from 'react'
import { navigate } from 'gatsby'

import Link from 'components/GatsbyLinkWrapper/index'
import SectionTopics from 'src/components/SectionTopics'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

const CheckingAccount = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const handleLink = (element: string, url: string) => {
    sendDatalayerEvent({
      section: 'dobra_2',
      section_name:
        'Digital Checking account Banco Inter: 100% digital, 100% free, 100% for you',
      element_action: 'click button',
      element_name: element,
      redirect_url: url,
    })
    navigate(url)
  }

  return (
    <section className='pt-5'>
      <div className='container'>
        <h3>
          Digital Checking account Banco Inter: 100% digital, 100% free, 100%
          for you
        </h3>
        <p>
          The first digital, free of charge, complete checking account. It has
          everything a traditional checking account has, but free, without
          lines, without agencies.
        </p>
        <p>
          How? You do everything using the App or the Internet Banking: bank
          statements, wire transfers, check and invoice deposit, prepaid top up,
          and more!
        </p>
        <p>
          You receive a MasterCard for purchases and withdraws on the 20k ATMs
          of the Banco24Horas network.
        </p>
        <SectionTopics
          title='Personal Account'
          size='md'
          styles='fs-md-26 fs-lg-36'
        >
          <h4 className='fs-22 fw-400'>Everything in the app, 100% free</h4>
          <Link
            onClick={() =>
              handleLink(
                'More information - Personal Account',
                '/en/digital-account/personal/',
            )
            }
            className='link-arrow-right'
          >
            More information
          </Link>
        </SectionTopics>
        <SectionTopics
          title='Microenterprise Account'
          size='md'
          styles='fs-md-22 fs-lg-32'
        >
          <h4 className='fs-22 fw-400'>
            The individual microenterprise needs a friendly bank even more
          </h4>
          <Link
            onClick={() =>
              handleLink(
                'More information - Microenterprise Account',
                '/en/digital-account/mei/',
            )
            }
            className='link-arrow-right'
          >
            More information
          </Link>
        </SectionTopics>
        <SectionTopics
          title='Corporate Account'
          size='md'
          styles='fs-md-26 fs-lg-36'
        >
          <h4 className='fs-22 fw-400'>
            Your company also deserves a free checking account
          </h4>
          <Link
            onClick={() =>
              handleLink(
                'More information - Corporate Account',
                '/en/digital-account/corporate/',
            )
            }
            className='link-arrow-right'
          >
            More information
          </Link>
        </SectionTopics>
      </div>
    </section>
  )
}

export default CheckingAccount
